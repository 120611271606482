<template>
  <header class="header">
    <div class="logo"><img alt="логотип" src="@/assets/images/logo.svg" /></div>
    {{ getHeader() }}
  </header>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    getHeader() {
      const title = document.location.hostname
      return title.substring(0, title.length - 3)
    }
  },
  mounted() {
    const title = document.location.hostname
    document.title = `${title.charAt(0).toUpperCase() + title.slice(1)} - быстрый займ`
  }
};
</script>

<style lang="scss">
.header {
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 65px;
  padding-top: 55px;
  gap: 12px;

  .logo {
    height: 64px;
    aspect-ratio: 1 / 1;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 30px;
    padding-top: 25px;
  }
}
</style>
