<template>
  <div class="loans">
    <div class="wrapper">
      <div class="card" v-for="offer in offers" :key="offer.id">
        <div class="dop" v-if="offer.isFeatured">
          <h4>0<span>%</span></h4>
          <p>ставка</p>
        </div>
        <img
          :src="offer.logo"
          :alt="offer.title"
          :title="offer.title"
          class="loan_img"
        />
        <div class="mob_bl">
          <div class="max-summ">
            <p class="des">Максимальная сумма:</p>
            <p class="mob">Макс. сумма:</p>
            <h4>{{ offer.header }} ₽</h4>
          </div>
          <div class="chance">
            <p class="des">Вероятность получения:</p>
            <p class="mob">Вероятность:</p>
            <h4>{{ offer.chance }}%</h4>
          </div>
        </div>
        <a :href="offer.link" target="_blank" class="btn">Оформить</a>
        <div class="info">
          <p>{{ offer.subHeader }}</p>
        </div>
        <div class="info ads">
          <p v-html="setAdsValue(offer.link, offer.org)"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loans',
  props: ['offers'],
  methods: {
    makeid(length) {
      let result = '';
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    setAdsValue(link, org) {
      const linkParams = new URL(link).searchParams;
      const param = linkParams.get('erid')
        ? linkParams.get('erid').split('?')[0]
        : 'LjN8K' + this.makeid(4);

      return `Реклама ${org} <br> Erid: ${param}`;
    },
  },
};
</script>

<style lang="scss">
.loans {
  margin-top: 55px;
  margin-bottom: 120px;

  @media screen and (max-width: 480px) {
    margin-bottom: 80px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .card {
      max-width: 400px;
      min-width: 320px;
      width: 33.33%;
      margin: 0;
      border: 1px solid #bdbdbd;
      padding: 40px;
      position: relative;
      margin-bottom: -1px;

      &:nth-child(3n + 2) {
        margin-left: -1px;
        margin-right: -1px;

        @media screen and (max-width: 970px) {
          margin-left: 0px;
          margin-right: 0px;
        }
      }

      @media screen and (max-width: 970px) {
        width: 50%;

        &:nth-child(odd) {
          margin-right: -1px;
        }
      }

      @media screen and (max-width: 700px) {
        width: 100%;
        padding: 15px 15px 25px;

        &:nth-child(odd) {
          margin-right: 0px;
        }

        .mob_bl {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
        }
      }

      @media screen and (max-width: 480px) {
        min-width: 280px;
        padding: 25px 15px;
      }

      .dop {
        position: absolute;
        right: 0;
        top: 0;
        width: 80px;
        height: 80px;
        background-color: #fd2f2f;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 2;

        @media screen and (max-width: 700px) {
          width: 56px;
          height: 56px;
        }

        h4 {
          font-size: 40px;
          line-height: 40px;
          color: #fff;
          font-family: 'Rubik-Medium';
          margin: 0;

          span {
            font-weight: normal;
            font-family: 'Rubik-Regular';
          }

          @media screen and (max-width: 700px) {
            font-size: 28px;
            line-height: 28px;
          }
        }

        p {
          font-size: 16px;
          font-family: 'Rubik-Regular';
          color: #fff !important;
          margin: 0;

          @media screen and (max-width: 700px) {
            font-size: 14px;
            line-height: 14px;
          }
        }
      }

      .loan_img {
        max-width: 200px;
        width: 100%;
        height: 65px;
        object-fit: contain;

        @media screen and (max-width: 480px) {
          max-width: 125px;
          height: 40px;
        }
      }

      .max-summ {
        margin-top: 15px;

        @media screen and (max-width: 700px) {
          margin-top: 0;
        }

        p {
          color: #828282;
          font-size: 14px;
          line-height: 20px;
          font-family: 'Rubik-Regular';
          margin: 0;

          @media screen and (max-width: 700px) {
            font-size: 12px;
            line-height: 14px;
            text-align: left;
          }
        }

        h4 {
          font-size: 32px;
          line-height: 40px;
          color: #000;
          font-family: 'Rubik-Medium';
          margin: 0;

          @media screen and (max-width: 700px) {
            text-align: left;
            font-size: 24px;
            line-height: 32px;
          }
        }
      }

      .chance {
        margin-top: 15px;

        @media screen and (max-width: 700px) {
          margin-top: 0;
        }

        p {
          color: #828282;
          font-size: 14px;
          line-height: 20px;
          font-family: 'Rubik-Regular';
          margin: 0;

          @media screen and (max-width: 700px) {
            font-size: 12px;
            line-height: 14px;
            text-align: right;
          }
        }

        h4 {
          font-size: 32px;
          line-height: 40px;
          color: #1ecc67;
          font-family: 'Rubik-Medium';
          margin: 0;

          @media screen and (max-width: 700px) {
            text-align: right;
            font-size: 24px;
            line-height: 32px;
          }
        }
      }

      .btn {
        margin: 20px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffcf3d;
        border-radius: 8px;
        color: #000;
        font-family: 'Inter-Bold';
        line-height: 24px;
        font-size: 16px;
        text-decoration: none;
        max-width: 240px;
        width: 100%;
        height: 56px;

        @media screen and (max-width: 700px) {
          margin-top: 10px;
          height: 40px;
        }
      }

      .info {
        margin-top: 15px;

        @media screen and (max-width: 700px) {
          margin-top: 10px;
        }

        p {
          color: #828282;
          font-size: 14px;
          line-height: 20px;
          font-family: 'Rubik-Regular';
          margin: 0;

          @media screen and (max-width: 700px) {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      .ads {
        p {
          font-size: 8px;
          line-height: 14px;
        }
      }

      .des {
        display: block;

        @media screen and (max-width: 700px) {
          display: none;
        }
      }

      .mob {
        display: none;

        @media screen and (max-width: 700px) {
          display: block;
        }
      }
    }
  }
}
</style>
